/* You can add global styles to this file, and also import other style files */
.capitalize {
    text-transform: capitalize;
}

.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.heading {
    text-align: center;
    margin-bottom: 30px;
    margin-top: 50px;
    display: inline-block;
}

.-hr {
    border-top: 1px solid #e4e3e3;
    margin-bottom: 25px;
    margin-top: 25px;
    width: 100%;
    display: inline-block;
    line-height: 0px;
}

.w-100 {
    width: 100%;
}

/* List items */
.checkmark-bullet {
    padding: 0 15px;
    margin: 15px auto 0;
    list-style-type: none;

    li {
        padding: 10px 0 10px 35px;
        position: relative;

        &:after {
            position: absolute;
            top: 5px;
            left: 0;
            width: 27px;
            height: 27px;
            background: url('/assets/image/checkmark.svg') no-repeat center center;
            background-size: 22px 22px;
            content: ' ';
        }
    }
}

/* Is mobile Device */
.isMobileDevice {
    display: flex;

    @media screen and (min-width: 769px) {
        display: none;
    }
}